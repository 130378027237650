import React from 'react';
import ReactDOM from 'react-dom/client';
import { initializeApp } from "firebase/app";
import { initializeAnalytics } from "firebase/analytics";

// pages
import Category from './pages/category';
import ScheduleBuilder from './pages/schedule-builder';
import Tournament from './pages/tournament';
import TournamentMatchCenter from './pages/tournament-match-center';

import 'bootstrap/dist/css/bootstrap.min.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const firebaseConfig = {
  apiKey: "AIzaSyDMj2ZQOTRxvs-fB7nbHYx_R_mxH2dodqQ",
  authDomain: "canoe-polo-live.firebaseapp.com",
  projectId: "canoe-polo-live",
  storageBucket: "canoe-polo-live.appspot.com",
  messagingSenderId: "678294885911",
  appId: "1:678294885911:web:7a02a5f28065ce74822e52",
  measurementId: "G-SCV13Z6HT1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
initializeAnalytics(app);

const router = createBrowserRouter([
  {
    path: '/tournament/:id/category/:category',
    element: <Category />,
    errorElement: <Category />,
  },
  {
    path: '/tournament/:id/setup',
    element: <ScheduleBuilder />
  },
  {
    path: '/tournament/:id',
    element: <Tournament />
  },
  {
    path: '/tournament/:id/match-center',
    element: <TournamentMatchCenter />
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
