/** modal to take a match following ScheduleMatch.
 * Modal will allow match actions as per MatchActions with cards, goals, etc.
 * Modal will have 2 separate updates, one for updating the teams, and one for updating the officials
 */
import React, { useState } from 'react';
import { Button, Card, Col, Container, Form, InputGroup, Modal, Row, Table } from 'react-bootstrap';
import { ActionType, MatchActions, ScheduleMatch } from '../domain/Types';
import Api from '../utils/api';

interface Props {
  showMatchViewer: boolean;
  match: ScheduleMatch;
  onHide: () => void;
  tournamentId: string;
}

const MatchCenterViewerModal = (props: Props) => {
  const api = new Api();
  const [team1MatchActions, setTeam1MatchActions] = useState<MatchActions[]>(props.match.team1Actions);
  const [team2MatchActions, setTeam2MatchActions] = useState<MatchActions[]>(props.match.team2Actions);
  const referee1Ref = React.useRef<HTMLInputElement>(null);
  const referee2Ref = React.useRef<HTMLInputElement>(null);
  const tableRef = React.useRef<HTMLSelectElement>(null);
  const playerNumberRef = React.useRef<HTMLInputElement>(null);
  const teamRef = React.useRef<HTMLSelectElement>(null);
  const [loading, setLoading] = React.useState(false);
  const [matchActions, setMatchActions] = useState<MatchActions[]>([]);

  const triggerSaveTableAndRef = () => {
    setLoading(true);
    const referee1 = referee1Ref.current?.value;
    const referee2 = referee2Ref.current?.value;
    const table = tableRef.current?.value;
    if (referee1 && referee2) {
      // api.updateMatchOfficials(props.tournamentId, props.match.gameNumber, referee1, referee2).then((data) => {
      //   console.log(data);
      //   window.location.reload();
      // });
    }
  };

  const triggerSaveMatch = () => {
    setLoading(true);
    api.saveMatchActions(props.tournamentId, props.match.gameNumber, team1MatchActions, team2MatchActions).then((data) => {
      console.log(data);
      setTeam1MatchActions([]);
      setTeam2MatchActions([]);
      window.location.reload();
    });
  };

  const triggerAddMatchAction = (e: React.FormEvent) => {
    e.preventDefault();
    const team = teamRef.current?.value;
    const playerNumber = playerNumberRef.current?.value;
    if (team && playerNumber) {
      if (team === props.match.team1) {
        setTeam1MatchActions([...team1MatchActions, {
          type: ActionType.SCORE,
          playerNumber: parseInt(playerNumber),
          time: new Date().toISOString()
        }]);
      } else {
        setTeam2MatchActions([...team2MatchActions, {
          type: ActionType.SCORE,
          playerNumber: parseInt(playerNumber),
          time: new Date().toISOString()
        }]);
      }
    }
  };

  const showTeamScoreComponent = (team: String, matchActions: MatchActions[]) => {
    const team1Score = matchActions.filter((action) =>
      action.type === ActionType.SCORE).length;
    return (
      <Card className='text center'>
        <Card.Header>{team1Score}</Card.Header>
        <Card.Body>
          <Card.Title>{team}</Card.Title>
        </Card.Body>
      </Card>
    );
  };

  return <Modal show={props.showMatchViewer} onHide={props.onHide} size="lg">
    <Modal.Header closeButton>
      <Modal.Title>{`Match ${props.match.gameNumber}`}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Container>
        <Row>
          <Col>
            {showTeamScoreComponent(props.match.team1, team1MatchActions)}
          </Col>
          <Col>
            {showTeamScoreComponent(props.match.team2, team2MatchActions)}
          </Col>
        </Row>

        {/* show the time, pitch, category, category number, ref and table in info table */}
        <Card className="mt-3">
          <Card.Header>
            <Card.Title>Info</Card.Title>
          </Card.Header>
          <Card.Body>
            <Table striped size="lg">
              <tbody>
                <tr>
                  <td>Time</td>
                  <td>{props.match.time}</td>
                </tr>
                <tr>
                  <td>Pitch</td>
                  <td>{`${props.match.pitch}`}</td>
                </tr>
                <tr>
                  <td>Div</td>
                  <td>{props.match.div}</td>
                </tr>
                <tr>
                  <td>Ref 1</td>
                  <td>{props.match.referee1}</td>
                </tr>
                <tr>
                  <td>Ref 2</td>
                  <td>{props.match.referee2}</td>
                </tr>
                <tr>
                  <td>Officials</td>
                  <td>{props.match.officials}</td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <Form onSubmit={(e) => { triggerAddMatchAction(e); }} >
          <Card>
            <Card.Body>
              {/* Add goal with player number and team. Team is a dropdown of the 2 teams */}
              <InputGroup>
                <Form.Control type="number" placeholder="Player Number" ref={playerNumberRef} />
                <Form.Control as="select" ref={teamRef}>
                  <option>{props.match.team1}</option>
                  <option>{props.match.team2}</option>
                </Form.Control>
              </InputGroup>
            </Card.Body>
            <Card.Footer>
              <Button variant="primary" type="submit">
                Add Goal
              </Button>
            </Card.Footer>
          </Card>
        </Form>
        <Card>

        </Card>
      </Container>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={props.onHide}>
        Close
      </Button>
      <Button variant="primary" onClick={()=>triggerSaveMatch()}>
        Save
      </Button>
    </Modal.Footer>
  </Modal>;
}


export default MatchCenterViewerModal;

