import React, { useEffect, useState, useRef } from "react";
import { BracketsViewer } from '../brackets-viewer/main';
import { ViewerData } from "../brackets-viewer/types";

const Viewer = (props: { jsonDbData: any; }) => {
  const bracketsViewer = new BracketsViewer();

  const selector = Math.random().toString(36).substring(2,15).replace(/\d/g,'');
  const viewRef = useRef<HTMLDivElement|null>(null);

  useEffect(() => {
    if(viewRef.current != null) {
      viewRef.current.innerHTML = '';
    }
    const data:ViewerData = {
      stages: props.jsonDbData.stage,
      matches: props.jsonDbData.match,
      matchGames: props.jsonDbData.match_game,
      participants: props.jsonDbData.participant,
    };
    bracketsViewer.render(data,{
      selector: `.${selector}`,
    });
  }, []);

  return <div className={`${selector} brackets-viewer`} ref={viewRef}></div>;
};

export default Viewer;