import React, { useEffect, useState } from "react";
import { Card, Container, Table } from "react-bootstrap";
import Spinner from "./spinner";
import { Pool, PoolTeam } from "../domain/Types";
import Api from "../utils/api";

interface Props {
  poolData: Pool;
}

const Component = (props: Props) => {
  const [poolData, setPoolData] = useState<Pool>(props.poolData);

  if (!poolData) {
    return <Spinner />;
  }

  const calculateGoalDifference = (goalsFor: number, goalsAgainst: number): number => {
    return goalsFor - goalsAgainst;
  };

  return <Container fluid className={"px-4"}>
    <Card>
      <Card.Header>
        <Card.Title>{`${poolData.name} (${poolData.div})`}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Table striped size="lg">
          <thead>
            <tr>
              <th>Rank</th>
              <th>Team Name</th>
              <th>P</th>
              <th>W</th>
              <th>L</th>
              <th>D</th>
              <th>GF</th>
              <th>GA</th>
              <th>GD</th>
              <th>Points</th>
            </tr>
          </thead>
          <tbody>
            {poolData.teams.map((team: PoolTeam, index) => {

              return <tr key={`${team.teamId}-${index}`}>
                <td>{index + 1}</td>
                <td>{team.teamName}</td>
                <td>{team.played}</td>
                <td>{team.won}</td>
                <td>{team.lost}</td>
                <td>{team.draw}</td>
                <td>{team.goalsFor}</td>
                <td>{team.goalsAgainst}</td>
                <td>{calculateGoalDifference(team.goalsFor, team.goalsAgainst)}</td>
                <td>{team.points}</td>
              </tr>;
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  </Container>;

};

export default Component;
