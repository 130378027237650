import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { Button, Card, Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import { helpers } from "brackets-manager";
import { DBViewerData, MatchView, DBViewerParticipant, DBViewerMatch } from "../domain/DBViewerData";
import { compare, fromTime, getCategoryAndCategoryNumber, toTime } from "../utils/helpers";
import Api from "../utils/api";
import Spinner from "./spinner";


const Viewer = (props: { scheduleData: DBViewerData[]; tournamentId: string; }) => {
  const api = new Api();

  const [matches, setMatches] = useState<MatchView[]>([]);
  const [teams, setTeams] = useState<DBViewerParticipant[]>(props.scheduleData.flatMap(category => category.participant.map(
    participant => {
      return {
        ...participant,
        uid: `${category.uid}_${participant.id}`
      };
    })));
  const [pitches, setPitches] = useState<number>(2);
  const [startTime, setStartTime] = useState<number>(800);
  const [endTime, setEndTime] = useState<number>(1800);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const parsedMatches = props.scheduleData.map(category => {
      //first we add the participant data into the match
      return {
        ...category,
        match: category.match
          .filter(match => {
            return match.opponent1 != null && match.opponent2 != null;
          })
          .map(match => {
            return {
              ...match,
              'opponent1': {
                ...match.opponent1,
                participant: category.participant.find(it => it.id === match.opponent1?.id) || undefined
              },
              'opponent2': {
                ...match.opponent2,
                participant: category.participant.find(it => it.id === match.opponent2?.id) || undefined
              },
              'pitch': 0,
              'time': 0,
              round: category.round.find(round => round.id === match.round_id)?.number || undefined,
              diagNames: [] as string[]
            };
          })
      };
    });

    const allRoundRobinMatches = parsedMatches
      .flatMap(category => {
        // now we extract the round robin matches to sort
        const stageId = category.stage.find(stage => stage.type === 'round_robin')?.id;
        console.log(stageId);
        return category.match.filter(match => {
          return match.stage_id === stageId;
        });
      })
      .sort((a, b) => {
        const roundComp = compare(a.round_id, b.round_id);
        if (roundComp === 0) {
          return compare(a.number, b.number);
        }
        return roundComp;
      });

    const allFinalMatches = parsedMatches
      .flatMap(category => {
        const finalRound = category.round[category.round.length - 1];

        const filteredMatches = category.match.filter(match => {
          return !allRoundRobinMatches.some(roundRobinMatch => roundRobinMatch.uid === match.uid);
        });

        filteredMatches.map(match => {
          const diagonalMatch = helpers.getDiagonalMatchNumber(match.number);
          const winnerGoesTo = filteredMatches.findIndex(it => it.number === diagonalMatch && it.round_id === match.round_id + 1);
          if (winnerGoesTo) {
            const filteredMatch = filteredMatches[winnerGoesTo];
            if (filteredMatch != undefined) {
              if (filteredMatch.round_id !== finalRound.id) {
                const stringifiedUid = getCategoryAndCategoryNumber(match as any);
                filteredMatches[winnerGoesTo].diagNames.push(`Winner of ${stringifiedUid.category} ${stringifiedUid.categoryMatchNumber}`);
              }
            }
          }
        });

        //TODO we need to figure out the text to print the semi text
        const finalists = filteredMatches.find(match => {
          return match.round_id === finalRound.id - 1;
        })?.diagNames || [];

        const updatedDiagNames = finalists.map(finalist => {
          return finalist.replace('Winner of ', 'Loser of ');
        });

        return filteredMatches.map(match => {
          if (match.round_id === finalRound.id) {
            return {
              ...match,
              diagNames: updatedDiagNames
            };
          }
          return match;
        });
      })
      .sort((a, b) => {
        const roundComp = compare(a.round, b.round);
        if (roundComp === 0) {
          return compare(a.number, b.number);
        }
        return roundComp;
      });

    console.log(allFinalMatches);


    const allMatches = [...allRoundRobinMatches, ...allFinalMatches];

    // @ts-expect-error null is not undefined error here for opponent.id
    setTimeAndPitch(allMatches);
  }, []);

  const setTimeAndPitch = (matches: MatchView[]) => {
    let maxPitches = pitches;
    let currentPitch = 0;
    let currentTime = startTime;
    let maxTime = endTime;

    setMatches(matches.map(match => {
      if (currentPitch === maxPitches) {
        match.pitch = 1;
        if (currentTime + 50 === maxTime) {
          match.time = startTime; //start of matches
        } else {
          match.time = currentTime + 50;
        }
      } else {
        match.pitch = currentPitch + 1;
        match.time = currentTime;
      }

      currentPitch = match.pitch;
      currentTime = match.time;
      return match;
    }));
  };

  function uidToText(match: DBViewerMatch) {
    const stringUid = getCategoryAndCategoryNumber(match);
    
    return `${stringUid.category} ${stringUid.categoryMatchNumber}`;
  }
  const triggerUpdate = () => {
    // saves the matches to the database
    setLoading(true);
    api.createSchedule(props.tournamentId, matches)
      .then(() => {
        setLoading(false);
      });
  };

  if(loading) {
    return <Spinner />
  }

  return (
    <Container fluid className={"px-4"}>
      <Card>
        <Card.Body>
          <Form>
            <Row>
              {/* need input for number of pitches */}
              <Col>
                <FormGroup>
                  <Form.Label>Number of Pitches</Form.Label>
                  <Form.Control type="number" defaultValue={pitches} onChange={(env) => setPitches(env.currentTarget.value as any)} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Form.Label>Start Time</Form.Label>
                  <Form.Control type="time" defaultValue={toTime(startTime)} onChange={(env) => setStartTime(fromTime(env.currentTarget.value))} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Form.Label>End Time</Form.Label>
                  <Form.Control type="time" defaultValue={toTime(endTime)} onChange={(env) => setEndTime(fromTime(env.currentTarget.value))} />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Card.Body>
        <Card.Footer>
          <Button onClick={() => setTimeAndPitch(matches)}>Update Base Schedule</Button>
        </Card.Footer>
      </Card>
      <Table striped size="lg">
        <thead>
          <tr>
            <th>Game</th>
            <th>Time</th>
            <th>Pitch</th>
            <th>Category</th>
            <th>Team 1</th>
            <th>Team 2</th>
            <th>Table</th>
            <th>Ref</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            matches.map((match, index) => {
              return (
                <tr key={`${match.uid} ${Math.random()}`}>
                  <td>
                    {index + 1}
                  </td>
                  <td>
                    <FormGroup>
                      <Form.Control type="time" defaultValue={toTime(match.time)} onChange={(env) => {
                        const newTime = fromTime(env.currentTarget.value);
                        matches[index].time = newTime;
                      }} />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup>
                      <Form.Control type="number" maxLength={2} max={pitches} min={0} defaultValue={match.pitch} onChange={(env) => {
                        const newPitch = parseInt(env.currentTarget.value);
                        matches[index].pitch = newPitch;
                      }} />
                    </FormGroup>
                  </td>
                  <td>
                    {uidToText(match)}
                  </td>
                  <td>
                    {match.opponent1?.participant?.name || match.diagNames[1] || match.diagNames[0]}
                  </td>
                  <td>
                    {match.opponent2?.participant?.name || match.diagNames[0]}
                  </td>
                  <td>
                    {/* Optional, dropdown for list of all available teams that are not playing */}
                    <FormGroup>
                      <Form.Control as="select" defaultValue={match.table} onChange={(env) => {
                        matches[index].table = env.currentTarget.value;
                      }}>
                        <option value="">None</option>
                        {
                          teams.map(team => {
                            return (
                              <option key={`${match.uid}_${team.uid}`} value={team.id}>{team.name}</option>
                            );
                          })
                        }
                      </Form.Control>
                    </FormGroup>
                  </td>
                  <td>
                    {/* Optional, text input for the name */}
                    <FormGroup>
                      <Form.Control type="text" defaultValue={match.referee} onChange={(env) => {
                        matches[index].referee = env.currentTarget.value;
                      }} />
                    </FormGroup>
                  </td>
                  {/* provide up and down buttons to move the matches. If the match moves, it takes the pitch, index and time of the replaced match. The replaced
                  match takes place, index and time of the moving match.
                  Only allow moving up when not first match. Only allow moving down if not first match
                  */}
                  <td>
                    <Button onClick={() => {
                      if (index > 0) {
                        const temp1 = matches[index - 1];  //get the match above
                        const temp2 = matches[index]; //get the match below
                        matches[index - 1] = temp2; //move the match below to the match above
                        matches[index] = temp1; //move the match above to the match below

                        console.log(temp1.pitch, temp2.pitch)
                        matches[index].pitch = temp1.pitch; //set the pitch of the match below to the pitch of the match above
                        matches[index].time = temp1.time; //set the time of the match below to the time of the match above
                        matches[index - 1].pitch = temp2.pitch; //set the pitch of the match above to the pitch of the match below
                        matches[index - 1].time = temp2.time; //set the time of the match above to the time of the match below
                        setMatches([...matches]);
                      }
                    }}>Up</Button>
                    <Button onClick={() => {
                      if (index < matches.length - 1) {
                        const temp1 = matches[index + 1];  //get the match below
                        const temp2 = matches[index]; //get the match above
                        matches[index + 1] = temp2; //move the match above to the match below
                        matches[index] = temp1; //move the match below to the match above
                        matches[index].pitch = temp1.pitch; //set the pitch of the match above to the pitch of the match below
                        matches[index].time = temp1.time; //set the time of the match above to the time of the match below
                        matches[index + 1].pitch = temp2.pitch; //set the pitch of the match below to the pitch of the match above
                        matches[index + 1].time = temp2.time; //set the time of the match below to the time of the match above
                        setMatches([...matches]);
                      }
                    }}>Down</Button>
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </Table>

      <Card>
        <Card.Footer>
          <Button onClick={() => triggerUpdate()}>Update Schedule</Button>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default Viewer;
