import { DBViewerMatch } from "../domain/DBViewerData";

export function compare(a: number | undefined, b: number | undefined) {
  if (a === undefined || b === undefined) {
    return 0;
  }
  if (a < b) {
    return -1;
  } else if (a > b) {
    return 1;
  }
  return 0;
}

export function toTime(time: number) {
  const hours = Math.floor(time / 100);
  //for minutes, use remainder as % of minutes in an hour
  const minutes = (time % 100) / 100 * 60;
  //ensure at least 2 digits for minutes
  return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${Math.round(minutes)}`;
}

export function fromTime(time: string) {
  const [hours, minutes] = time.split(':');
  return parseInt(hours) * 100 + parseInt(minutes);
}

export function getCategoryAndCategoryNumber(match: DBViewerMatch): { category: string, categoryMatchNumber: number } {
  const uidString = match.uid.replace(/_/g, ' ');
  // take last word, convert to number and add 1
  const categoryMatchNumber = parseInt(uidString.split(' ').pop() || '0') + 1;

  const category = uidString.replace(/\d+$/, '').trim().toUpperCase();
  return { category, categoryMatchNumber };
}
