import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Viewer from '../components/bracket-viewer';
import Api from "../utils/api";

function Page() {
  const api = new Api();
  const [data, setData] = useState(null);
  const { id, category } = useParams();

  useEffect(() => {
    if (id && category) {
      api.getCategory(id, category).then(data => {
        setData(data);
      });
    }
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }
  return <Viewer jsonDbData={data} />;
}

export default Page;
