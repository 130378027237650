import React, { useEffect, useState } from "react";
import Spinner from "../components/spinner";
import { useParams } from "react-router-dom";
import Viewer from '../components/schedule-builder';
import Api from "../utils/api";

// page that takes tournament ID from url param :id
function Page() {
  const [data, setData] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const api = new Api();
    api.getTournament(id as string).then(data => {
      setData(data);
    });
  }, []);

  if (!data) {
    return <Spinner />;
  }

  return <Viewer scheduleData={data} tournamentId={id as string} />;
}

export default Page;
