export interface Tournament {
  uid: string,
  title: string,
  schedule: ScheduleMatch[],
  pools: Pool[]
}

export interface ScheduleMatch {
  div: String,
  gameNumber: Number,
  time: String,
  pitch: Number,
  type: String,
  team1: String,
  team2: String,
  referee1: String,
  referee2: String,
  officials: String,
  status: MatchStatus,
  team1Actions: MatchActions[],
  team2Actions: MatchActions[],
}

export interface Pool {
  name: string,
  div: string,
  teams: PoolTeam[]
}

export interface PoolTeam {
  teamName: string,
  teamId: string,
  played: number,
  won: number,
  lost: number,
  draw: number,
  goalsFor: number,
  goalsAgainst: number,
  points: number
}

export enum MatchStatus {
  "PREPARED",
  "PENDING_RESULT",
  "PLAYING",
  "COMPLETED"
}

export interface MatchActions {
  type: ActionType,
  playerNumber?: Number,
  time: String;
}

export enum ActionType {
  "SCORE"="SCORE",
  "CARD-YELLOW"="CARD-YELLOW",
  "CARD-GREEN"="CARD-GREEN",
  "CARD-RED"="CARD-RED"
}
