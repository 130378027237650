import { MatchActions } from "../domain/Types";

const localApi = 'http://localhost:5000/canoe-polo-live/us-central1/tournamentapi/api';
const publicApi = 'https://tournament.canoepololive.co.za/api';

export default class Api {
  baseUrl: string;
  constructor() {
    this.baseUrl = window.location.hostname.includes('localhost') ? localApi : publicApi;
  }

  getTournament = async (tournamentId: string) => {
    const response = await fetch(`${this.baseUrl}/tournament/${tournamentId}`);
    return response.json();
  };

  getCategory = async (tournamentId: string, categoryId: string) => {
    const response = await fetch(`${this.baseUrl}/tournament/${tournamentId}/${categoryId}`);
    return response.json();
  };

  createSchedule = async (tournamentId: string, allMatches: any[]) => {
    const response = await fetch(`${this.baseUrl}/tournament/${tournamentId}/schedule/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ allMatches })
    });
    return response.json();
  };

  getSchedule = async (tournamentId: string) => {
    const response = await fetch(`${this.baseUrl}/tournament/${tournamentId}/schedule`);
    return response.json();
  };

  getPool = async (tournamentId: string, poolId: string) => {
    const response = await fetch(`${this.baseUrl}/tournament/${tournamentId}/pool/${poolId}`);
    return response.json();
  };

  saveMatchActions = async (tournamentId: string, gameNumber: Number, team1MatchActions: MatchActions[], team2MatchActions: MatchActions[]) => {
    const response = await fetch(`${this.baseUrl}/tournament/${tournamentId}/result/${gameNumber}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ matchData: { team1Actions: team1MatchActions, team2Actions: team2MatchActions } })
    });
    return response.json();
  };

  updateMatchResult = async (tournamentId: string, categoryId: string, matchId: number, team1Score: number, team2Score: number) => {
    const response = await fetch(`${this.baseUrl}/tournament/${tournamentId}/${categoryId}/match/${matchId}/result`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ team1Score, team2Score })
    });
    return response.json();
  };

  updateMatchOfficials = async (tournamentId: string, matchUid: string, table: string, ref: string) => {
    const response = await fetch(`${this.baseUrl}/tournament/${tournamentId}/scheduling/${matchUid}/officials`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ table, ref })
    });
    return response.json();
  };
}


