import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Spinner from "../components/spinner";
import Schedule from "../components/schedule-viewer";
import Api from "../utils/api";
import { Tournament } from "../domain/Types";
import PoolViewer from "../components/pool-viewer";

const Page = () => {
  const api = new Api();
  const { id } = useParams();
  const [tournament, setTournament] = useState<Tournament | null>(null);

  useEffect(() => {
    api.getSchedule(id as string).then(data => {
      console.log(data);
      setTournament(data);
    });
  }, []);

  if (!tournament) {
    return <Spinner />;
  }

  return <Container fluid className={"px-4"}>
    <Card>
      <Card.Header>
        <Card.Title>Tournament Viewer</Card.Title>
      </Card.Header>
      <Card.Body>
        <Schedule
          matches={tournament.schedule}
          isMatchCenter={false}
          tournamentId={id as string}
        />
        <Row>
          {tournament.pools.map((pool, index) => {
            return <Col key={`pool-${index}`}>
              <PoolViewer
                poolData={pool}
              />
            </Col>;
          })}
        </Row>
      </Card.Body>
    </Card>
  </Container>;

};

export default Page;
