import React, { useState } from "react";
import { Button, Card, Container, Table } from "react-bootstrap";
import { ActionType, MatchActions, MatchStatus, ScheduleMatch } from "../domain/Types";
import MatchCenterViewerModal from "./match-center-tournament-modal";

interface Props {
  matches: ScheduleMatch[],
  isMatchCenter: boolean,
  tournamentId: string
}

const Component = (props: Props) => {
  const scheduleData = props.matches
  const [targetMatch, setTargetMatch] = useState<ScheduleMatch | null>(null);
  const [triggerEditMatch, setTriggerEditMatch] = useState<boolean>(false);

  const getScore = (actions: MatchActions[]): number => {
    return actions.filter((action) => action.type === ActionType.SCORE).length;
  };

  const showScore = (matchStatus: MatchStatus, actions: MatchActions[]) => {
    if (matchStatus === MatchStatus.COMPLETED || matchStatus === MatchStatus.PLAYING) {
      return getScore(actions);
    }
    return '';
  };

  return <Container fluid className={"px-4"}>
    <Card>
      <Card.Header>
        <Card.Title>Schedule</Card.Title>
      </Card.Header>
      <Card.Body>
        <Table striped size="lg" hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Div</th>
              <th>Time</th>
              <th>Pitch</th>
              <th>Team 1</th>
              <th>Score 1</th>
              <th>Score 2</th>
              <th>Team 2</th>
              <th>Ref 1</th>
              <th>Ref 2</th>
              <th>Officials</th>
              {props.isMatchCenter ? <th>Actions</th> : null}
            </tr>
          </thead>
          <tbody>
            {scheduleData.map((match: ScheduleMatch, index) => {
              return <tr key={`${match.div}-${index}`}>
                <td>{`${match.gameNumber}`}</td>
                <td>{match.div}</td>
                <td>{match.time}</td>
                <td>{`${match.pitch}`}</td>
                <td>{match.team1}</td>
                <td>{showScore(match.status, match.team1Actions)}</td>
                <td>{showScore(match.status, match.team2Actions)}</td>
                <td>{match.team2}</td>
                <td>{match.referee1}</td>
                <td>{match.referee2}</td>
                <td>{match.officials}</td>
                {props.isMatchCenter ? <td>
                  <Button onClick={() => {
                    setTargetMatch(match);
                    setTriggerEditMatch(true);
                  }}>Update</Button>
                </td> : null}
              </tr>;
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
    {props.isMatchCenter && targetMatch != null ? 
    <MatchCenterViewerModal
      showMatchViewer={triggerEditMatch}
      onHide={() => setTriggerEditMatch(false)}
      match={targetMatch as ScheduleMatch}
      tournamentId={props.tournamentId}
    /> : null}
  </Container>;

};

export default Component;
