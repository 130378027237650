import React from "react";
import { Container, Spinner as BootstrapSpinner } from "react-bootstrap";

export default function Spinner() {
  return <Container className="w-100 h-100">
    <div className="h-100 d-flex align-items-center justify-content-center">
      <BootstrapSpinner animation="border" role="status" className="">
        <span className="sr-only"></span>
      </BootstrapSpinner>
    </div>
  </Container>;
}